<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div id="page-user-list">
<div class="card_margin-bottom">
    <div class="vx-card p-6">
    <div class="flex flex-wrap items-center overlap_field">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow" style="display:none;">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
             
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      
        <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="$router.push('/employee/new').catch(() => {})">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
      <div class="dd-actions cursor-pointer mr-4">
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
        </div>
		 <div class="dd-actions cursor-pointer mr-4">
         <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon " @click="addDataPrompt()">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 leading-none">Import Employee</span>
          </button>
		  </div>
  <vs-prompt
            :title="promptName"
            :accept-text= "acceptText"
            button-cancel = "border"
             @accept="importEmployee(e)"
             :is-valid="validateForm"
            :active.sync="displayPrompt"
            >
            <div>
                <form>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                           <div class="mt-4 text-center" >
                                <input type="file" ref="du" class="hidden" @change="onFileChange" accept=".csv" name="import_csv" id="import_csv">
                                <vs-button size="small" type="border" @click="$refs.du.click()">
                                <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                                <span class="ml-2 text-base text-primary">Upload CSV</span>                                
                              </vs-button>  
                               <div class="mt-4 text-center uploadedFile">
                                <vs-chip class="uploaded_media_url new_upload_file_csv">{{this.uploadedFile}}</vs-chip>
                              </div><br>
                               <div class="sampletext-center"><a href="/Employee_Import .csv" download>Sample</a></div>
                            </div>                            
                        </div>
                    </div>
                </form>
            </div>
        </vs-prompt>
        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4 search_main_head" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          
        <vs-button class="mb-4 md:mb-0" style="background:white !important; color: #1c55a2; border:1px solid #1c55a2; " @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> 

          <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>
              <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
          
      </div>
    </div>
    </div>

    <div class="vx-card p-6">
    <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table data-table-width active_btn"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="employees"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'

// Store Module
//import moduleEmployee from '@/store/employee/moduleEmployee.js'

// Cell Renderer
import CellRendererStatus from './EmployeeCellRenderer/EmployeeCellRendererStatus.vue'
import CellRendererVerified from './EmployeeCellRenderer/EmployeeCellRendererVerified.vue'
import CellRendererActions from './EmployeeCellRenderer/EmployeeCellRendererActions.vue'
import CellRendererDate from './EmployeeCellRenderer/EmployeeCellRendererDate.vue'



export default {
  components: {
    AgGridVue,
    vSelect,
    // Cell Renderer
    CellRendererStatus,
    CellRendererVerified,
    CellRendererDate,
    CellRendererActions
  },
  data () {
    return {
       media_url:'',
      promptName           :'Import Employee',
      acceptText           :'Import Employee',
      formData             : {"csv":""},
      displayPrompt        : false,
      uploadedFile:'',
       uploadFile:0,
      searchQuery: '',
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'S.NO',
          field: 'employee_id',
          width: 85,
          filter: false,
        },
        {
          headerName: 'Name',
          field: 'first_name',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: true,
          width: 310
        },
        {
          headerName: 'Date',
          field: 'created_at',
          filter: false,
          width: 165,
          cellRendererFramework: 'CellRendererDate'
        },
        {
          headerName: 'Status',
          field: 'emp_status',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererStatus'

        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          cellRendererFramework: 'CellRendererActions'
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererStatus,
        CellRendererVerified,
        CellRendererDate,
        CellRendererActions
      }
    }
  },
  computed: {
    employees () {
      return this.$store.getters['employee/queriedEmployees'] 
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    validateForm () {
        return this.uploadFile == 1
    }
  },
  methods: {
      refreshData () {
        this.$vs.loading()
        this.$store.dispatch('employee/fetchEmployees').then(() => { this.$vs.loading.close() })
    },
    resetColFilters () {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
  onFileChange(e) {
      this.uploadedFile =e.target.value.slice(12)
      var files = e.target.files || e.dataTransfer.files;
      const totalsize = e.target.files[0]['size']
      var totalSizeMB = totalsize / Math.pow(1024,2)
      if(totalSizeMB > 5){
        const error = "File size more 5 MB can not been upload.Try with other image less than 5 MB.....!!!!"
          this.notif_failed(error)
      }else{
          this.uploadFile= 1
          if (!files.length)
            return;
          this.formData.csv = files[0];
      }
    },
    notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
       addDataPrompt () {
         this.uploadedFile= ""
         this.uploadFile= 0
      this.formData = {"csv":""}
      this.promptName = 'Import Employee'
      this.displayPrompt = true

    },
     importEmployee() {
      this.$vs.loading()
         this.$store.dispatch('employee/insertimportEmployee', Object.assign({}, this.formData)).then((res) => {
           console.log('res',res.data.successCount)
              const success = 'Employee Details Imported with newly added count ' +  res.data.successCount + ' updated Count ' + res.data.failureCount
              this.$store.dispatch('employee/fetchEmployees').then((res) => { 
                 this.uploadFile= 0
                this.$vs.loading.close()
                this.notif_sucess(success) 
               })
          
             
            }).catch((error) => { 
              this.uploadFile= 0
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
    importEmployee1() {
      this.$vs.loading()
         this.$store.dispatch('employee/insertimportEmployee', Object.assign({}, this.formData)).then(() => {
              const success = 'Employee Details Imported Successfully...!!!'
              this.$store.dispatch('employee/fetchEmployees').then(() => { 
                this.uploadFile= 0
                this.$vs.loading.close()
                this.notif_sucess(success) 
               })
            }).catch((error) => { 
                  this.uploadFile= 0
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
        notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
      notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
   if(this.employees.length === 0){
      this.$vs.loading()
      this.$store.dispatch('employee/fetchEmployees').then(() => { this.$vs.loading.close() })
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.ag-floating-filter-button {
    display: none;
}
.add_new {
    position: absolute;
}
.refresh_data {
    position: absolute;
    left: 181px;
}
.vs-input--input {
    border-radius: 16px;
}
.import_employeee{
      position: relative;
    right: 203px;
    height: 39px;
}
.search_main_head {position: absolute !important;
    right: 0 !important;}
</style>
