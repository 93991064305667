<template>
<div class="comman_button">
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-if="this.params.data.status !=2">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
    <div class="delete_employee" :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-else-if="this.params.data.status ==2">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
    </div>
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
       this.$router.push("/employee/edit/" + this.params.data.employee_id).catch(() => {})
    },
 confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        "exact-active-class":"vdialognew",
        title: 'Confirm Delete',
        text: `You Are About To Delete Employee "${this.params.data.first_name}"`,
        accept: this.deleteEmployee,
        acceptText: 'Confirm'
      })
    },
    deleteEmployee () {
      this.$vs.loading()
      this.$store.dispatch('employee/deleteEmployee', this.params.data.employee_id).then(() => { 
        this.$store.dispatch('employee/fetchEmployees')
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          title: 'Employee Deleted',
          text: 'Employee Removed Successfully...!!!'
        })
       })
    },
      notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'User Deleted',
        text: 'Employee Removed Successfully...!!!'
      })
    }
  }
}
</script>

<style>
button.vs-component.vs-button.vs-dialog-accept-button.vs-button-danger.vs-button-filled {
    width: -webkit-fill-available;
    height: 49px;
}
</style>

