<template>

 <vs-chip v-if="params.value == 'New'" class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>New</span>
    </vs-chip>

    <vs-chip v-else-if="params.value == 'Active'" class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>Active</span>
    </vs-chip>
    <vs-chip v-else-if="params.value == 'In active'" class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>InActive</span>
    </vs-chip>
     <vs-chip v-else-if="params.value == 'Deleted'" class="ag-grid-cell-chip" :color="chipColor(params.value)">
      <span>Deleted</span>
    </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
    chipColor () {
      return (value) => {
        if (value === 'Active') return 'success'
         else if (value === 'New') return 'success'
        else if (value === 'In active' || value === 'Deleted' ) return 'danger'
        else return 'primary'
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
