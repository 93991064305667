<template>
    <span>{{ this.dateFormat(params.value) }}</span>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CellRendererStatus',
  methods: {
    dateFormat (value) {
         const d = new Date(moment(value))
      return d.toDateString()
    }
  }
}
</script>
